import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import CrmLanding from "../views/CrmLanding";
import RegisterBusiness from "../views/RegisterBusiness";
import MySales from "../views/MySales";
import LeadsDiscovery from "../views/LeadsDiscovery";
import SubscriptionExpiredWidget from "../ones/SubscriptionExpiredWidget";

export default function Crm(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
    appContext.getCurrentBusinessData();
  }

  function getView() {
    if (appContext.doesUserHasBusiness()) {
      if(appContext.currentBusinessData?.subscriptionStatus === 'active') {
        //..
        if (
          !appContext.navSubItem &&
          !appContext.navExtraItem &&
          !appContext.navMoreItem
        ) {
          return <CrmLanding />
        } else if (
          appContext.navSubItem === 'my-sales' &&
          !appContext.navExtraItem &&
          !appContext.navMoreItem
        ) {
          return <MySales />
        } else if (
          appContext.navSubItem === 'leads-discovery' &&
          !appContext.navExtraItem &&
          !appContext.navMoreItem
        ) {
          return <LeadsDiscovery />
        }
        //..
      } else {
        return <SubscriptionExpiredWidget/>
      }
    } else {
      return <RegisterBusiness />
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem, appContext.currentBusinessData])

  //return desired view
  return view;
}