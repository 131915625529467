import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_ADD_STOCK, STR_AMOUNT_PAID, STR_AVAILABLE_QUANTITY, STR_BUSINESS, STR_BUSINESS_NAME, STR_CLIENT, STR_COMPANY, STR_CUSTOMER_LEVEL, STR_CUSTOMER_TYPE, STR_DASHBOARD, STR_EDIT_STOCK, STR_EMAIL_ADDRESS, STR_GOVERNMENT, STR_INCLUDE_COUNTRY_CODE_ON_PHONE, STR_INCLUDE_LINK_TO_PROFILE, STR_INVALID_AMOUNT_PAID, STR_INVALID_BUSINESS_NAME, STR_INVALID_CODE, STR_INVALID_NAME, STR_INVALID_PHONE, STR_INVALID_UNIT, STR_INVALID_UNIT_PRICE, STR_ITEM_TYPE, STR_LEAD, STR_LOCATION, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO_BUSINESS_WARNING, STR_NO_OPTIONS, STR_NOTES, STR_OTHER_COMM_CHANNELS, STR_PERSON, STR_PHONE_NUMBER, STR_PLEASE_WAIT, STR_PRODUCT_CODE, STR_START_FREE_TRIAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE_RECORD_CUSTOMER, STR_TITLE_REGISTER_BUSINESS, STR_TODOS, STR_TOTAL_AMOUNT, STR_UNIT, STR_UNIT_BUYING_PRICE, STR_UNIT_PRICE, STR_UNIT_RETAIL_SELLING_PRICE, STR_UNIT_WHOLESALE_SELLING_PRICE, STR_VENDOR, STR_WATEJA } from "../Strings";
import { TiWarningOutline } from "react-icons/ti";
import { MdClose } from "react-icons/md";
import Select from "react-select";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null); //vendor data
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [unit, setUnit] = useState("");
  const [unitRetailSellingPrice, setUnitRetailSellingPrice] = useState(0);
  const [unitWholesaleSellingPrice, setUnitWholesaleSellingPrice] = useState(0);
  const [unitBuyingPrice, setUnitBuyingPrice] = useState(0);


  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      getData(),
    ])

    setReady(true);
  }

  async function getData() {
    await callApi("get-stock-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  
  async function editStock() {
    if (!loading) {
			if(code.trim().length > 0 || data.itemType === 'non-stock') {
				if(name.trim().length > 0) {
					if(unit.trim().length > 0) {
						if(!isNaN(Number(unitRetailSellingPrice)) && Number(unitRetailSellingPrice) > 0) {
							if(!isNaN(Number(unitWholesaleSellingPrice)) && Number(unitWholesaleSellingPrice) > 0) {
                if(Number(unitBuyingPrice) > 0 || data.itemType === 'non-stock') { 
                  
                  setLoading(true);
                  await callApi(
                    "edit-stock",
                    {
                      id: data.id,
                      code,
                      name,
                      unit,
                      unitRetailSellingPrice,
                      unitWholesaleSellingPrice,
                      unitBuyingPrice,
                    }
                  ).then(response => {
                    if(response.status === 1) {
                      appContext.tellMessage(STR_SUCCESS[appContext.language]);
                      appContext.getStockList();
                      appContext.getTotalStockValue();
                    } else {
                      appContext.tellError(response.msg);
                    }
                  })
                  setLoading(false);
                  
                } else {
                  appContext.tellError(STR_INVALID_UNIT_PRICE[appContext.language]);
                }
								
							} else {
								appContext.tellError(STR_INVALID_UNIT_PRICE[appContext.language]);
							}
						} else {
							appContext.tellError(STR_INVALID_UNIT_PRICE[appContext.language]);
						}
					} else {
						appContext.tellError(STR_INVALID_UNIT[appContext.language]);
					}
				} else {
					appContext.tellError(STR_INVALID_NAME[appContext.language]);
				}
			} else {
				appContext.tellError(STR_INVALID_CODE[appContext.language]);
			}
		} else {
			appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
		}
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  useEffect(() => {
    if (data) {
      setCode(data.code);
			setName(data.name);
			setUnitWholesaleSellingPrice(data.unitWholesaleSellingPrice);
			setUnitRetailSellingPrice(data.unitRetailSellingPrice);
      setUnitBuyingPrice(Number(data.unitBuyingPrice));
			setUnit(data.unit);
    }
  }, [data])

  if (ready) {
    return (

      <div className="container mw-700">
        <h6 className="mSectionTitle">{STR_EDIT_STOCK[appContext.language]}</h6>


        <div className="row">

          <div className="col-md-6 mb-4" style={{ display: (data.itemType === 'stock') ? 'block' : 'none' }}>
            <label className="form-label">{STR_PRODUCT_CODE[appContext.language]}*</label>
            <input
              className="form-control"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_NAME[appContext.language]}*</label>
            <input
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_UNIT[appContext.language]}*</label>
            <input
              className="form-control"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4" style={{ display: (data.itemType === 'stock') ? 'block' : 'none' }}>
            <label className="form-label">{STR_UNIT_BUYING_PRICE[appContext.language]}*</label>
            <input
              className="form-control"
              value={unitBuyingPrice}
              onChange={(e) => setUnitBuyingPrice(e.target.value)}

            />
          </div>          

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_UNIT_RETAIL_SELLING_PRICE[appContext.language]}*</label>
            <input
              className="form-control"
              value={unitRetailSellingPrice}
              onChange={(e) => setUnitRetailSellingPrice(e.target.value)}

            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_UNIT_WHOLESALE_SELLING_PRICE[appContext.language]}*</label>
            <input
              className="form-control"
              value={unitWholesaleSellingPrice}
              onChange={(e) => setUnitWholesaleSellingPrice(e.target.value)}

            />
          </div>

          <div className="col-md-12 mb-4 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={editStock}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}