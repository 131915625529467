import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import MoreMenu from "../views/MoreMenu";
import RegisterBusiness from "../views/RegisterBusiness";
import Users from '../views/Users';
import Business from '../views/Business';
import NoPermission from "../views/NoPermision";
import BonusManagement from '../views/BonusManagement';
import CommissionManagement from '../views/CommissionManagement';
import BillsManagement from '../views/BillsManagement';
import Vendors from '../views/Vendors';
import CustomerDeposits from '../views/CustomerDeposits';
import AccountsManager from '../views/AccountsManager';
import Reports from '../views/Reports';
import CashExpenses from '../views/CashExpenses';
import CashIncome from '../views/CashIncome';
import AccountingJournal from '../views/AccountingJournal';
import AllCustomers from '../views/AllCustomers';
import BusinessUserActivities from '../views/BusinessUserActivities';
import SubscriptionExpiredWidget from "../ones/SubscriptionExpiredWidget";

export default function More(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
    appContext.getCurrentBusinessData();
  }

  function getView() {

    if (appContext.doesUserHasBusiness()) {

      if(appContext.currentBusinessData?.subscriptionStatus === 'active') {
        if (
          appContext.currentUserRole === 'manager' ||
          appContext.currentUserRole === 'accountant' ||
          appContext.currentUserRole === 'cashier' ||
          appContext.currentUserRole === 'coach'
        ) {
          if (
            !appContext.navSubItem &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <MoreMenu />
          } else if (
            appContext.navSubItem === 'users' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <Users />
          } else if (
            appContext.navSubItem === 'business' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <Business />
          } else if (
            appContext.navSubItem === 'bonus_management' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <BonusManagement />
          } else if (
            appContext.navSubItem === 'commission_management' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <CommissionManagement />
          } else if (
            appContext.navSubItem === 'bills' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <BillsManagement />
          } else if (
            appContext.navSubItem === 'vendors' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <Vendors />
          } else if (
            appContext.navSubItem === 'customer_deposits' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <CustomerDeposits />
          } else if (
            appContext.navSubItem === 'accounts_manager' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <AccountsManager />
          } else if (
            appContext.navSubItem === 'reports' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <Reports />
          } else if (
            appContext.navSubItem === 'cash_expenses' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <CashExpenses />
          } else if (
            appContext.navSubItem === 'cash_income' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <CashIncome />
          } else if (
            appContext.navSubItem === 'accounting_journal' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <AccountingJournal />
          } else if (
            appContext.navSubItem === 'all_customers' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <AllCustomers />
          } else if (
            appContext.navSubItem === 'business_user_activities' &&
            appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <BusinessUserActivities businessUserId={appContext.navExtraItem} />
          }
        } else {
          return <NoPermission />
        }
      } else {
        return <SubscriptionExpiredWidget/>
      }


    } else {
      return <RegisterBusiness />
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem, appContext.currentBusinessData])

  //return desired view
  return view;
}