import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import AccountingLanding from "../views/AccountingLanding";
import RegisterBusiness from "../views/RegisterBusiness";
import Stock from '../views/Stock';
import Sales from '../views/Sales';
import NoPermission from "../views/NoPermision";
import SubscriptionExpiredWidget from "../ones/SubscriptionExpiredWidget";



export default function Accounting(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
    appContext.getCurrentBusinessData();
  }

  function getView() {
    if (appContext.doesUserHasBusiness()) {
      if(appContext.currentBusinessData.subscriptionStatus === 'active') {
        //..
        if (
          appContext.currentUserRole === 'manager' ||
          appContext.currentUserRole === 'accountant' ||
          appContext.currentUserRole === 'cashier' ||
          appContext.currentUserRole === 'coach'
        ) {
          if (
            !appContext.navSubItem &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <AccountingLanding />
          } else if (
            appContext.navSubItem === 'stock' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <Stock />
          } else if (
            appContext.navSubItem === 'sales' &&
            !appContext.navExtraItem &&
            !appContext.navMoreItem
          ) {
            return <Sales />
          }
        } else {
          return <NoPermission />
        }
        //..
      } else {
        return <SubscriptionExpiredWidget/>
      }
    } else {
      return <RegisterBusiness />
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem, appContext.currentBusinessData])

  //return desired view
  return view;
}